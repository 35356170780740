/**
 * @author Álvaro Fuentes <alvaro.fuentes.zurita@gmail.com>
 */
import { Empty, Const, nopString, HttpClientInterface, Http, Clockable, Clock, loadConfig } from '@afz/base-library';
import { SsoAppConfig } from './types.js';

export let config: Const<SsoAppConfig>;

export let api: HttpClientInterface;

export let clock: Clockable;

function composeUrl(path?: string | URL): URL {
  return new URL(path || nopString, window.location.origin + import.meta.env.BASE_URL);
}

export async function initBase(): Promise<void> {
  config = await loadConfig(
    composeUrl('.config.json'),
    {
      appVendor: 'Currant',
      appTitle: 'SSO',
      appVersion: 'v1.0.0',
      apiUrl: (value: string | Empty): URL => new URL(value || 'https://currant.cl/api/'),
      defaultRedirectionUrl: (value: string | Empty): URL => new URL(value || 'https://currant.cl/apps/sga/'),
      uiTitle: 'Currant Suite',
      uiSubtitle: 'Ingreso al sistema',
      hideUiSubtitle: false,
      userPlaceholder: 'Ingrese usuario',
      sessionDomain: 'currant.cl',
    },
    import.meta.env.MODE,
  );

  if (window.location.hostname !== config.sessionDomain) {
    throw new Error(
      `El dominio "${window.location.hostname}" no corresponde con el dominio de sesión configurado: "${config.sessionDomain}"`,
    );
  }

  api = Http.create({
    baseUrl: config.apiUrl,
    errorAdapter(body) {
      const error = new Error((body as { message?: string }).message);
      error.name = (body as { name?: string }).name || error.name;
      return error;
    },
  });

  clock = Clock.create(); // @todo Usar hora de servidor
}
